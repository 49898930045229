import { useEffect, useRef, useState } from "react";
import LogoSvg from "../../../assets/svg/logo.svg";
import {
  DeviceIcon,
  DataBreachIcon,
  HiddenFeesIcon,
} from "../../../assets/icons";
import "./index.scss";

const HeroSection = () => {
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentState, setCurrentState] = useState("initial");
  const headerRef = useRef<HTMLDivElement>(null);
  const heroRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const extendedContentRef = useRef<HTMLDivElement>(null);
  const storyRef = useRef<HTMLDivElement>(null);
  const founderRef = useRef<HTMLDivElement>(null);
  const stepsRef = useRef<HTMLDivElement>(null);
  const newsletterRef = useRef<HTMLDivElement>(null);
  const [scrollProgress, setScrollProgress] = useState(0);
  const currentProgress = useRef(0);

  // Start animation after mount
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInView(true);
    }, 100); // Small delay to ensure clean animation start

    return () => clearTimeout(timer);
  }, []);

  // Animation loop
  useEffect(() => {
    if (!isInView) return;

    const states = [
      "initial",
      "selecting",
      "selected",
      "pause",
      "processing",
      "processing",
      "completed",
      "completed",
    ];
    let currentIndex = 0;
    const timePerState = 1000;

    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % states.length;
      setCurrentState(states[currentIndex]);
    }, timePerState);

    return () => clearInterval(interval);
  }, [isInView]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsExpanded(entry.isIntersecting);
      },
      {
        threshold: 0.1,
        rootMargin: "-20% 0px -20% 0px",
      }
    );

    if (extendedContentRef.current) {
      observer.observe(extendedContentRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    let animationFrameId: number;
    let isAnimating = false;

    const updateProgress = () => {
      if (headerRef.current) {
        setIsHeaderSticky(window.scrollY > 20);
      }

      if (heroRef.current) {
        const rect = heroRef.current.getBoundingClientRect();
        const targetProgress = Math.min(
          1,
          Math.max(0, -rect.top / rect.height)
        );

        // Smooth interpolation with easing
        const ease = 0.15; // Increased from 0.1 for smoother transitions
        currentProgress.current +=
          (targetProgress - currentProgress.current) * ease;

        // Update scroll progress state only if the change is significant
        if (Math.abs(currentProgress.current - scrollProgress) > 0.001) {
          setScrollProgress(currentProgress.current);
        }

        // Update CSS custom property
        heroRef.current.style.setProperty(
          "--scroll-progress",
          currentProgress.current.toString()
        );

        if (Math.abs(targetProgress - currentProgress.current) > 0.001) {
          isAnimating = true;
          animationFrameId = requestAnimationFrame(updateProgress);
        } else {
          isAnimating = false;
        }
      }
    };

    const handleScroll = () => {
      if (!isAnimating) {
        animationFrameId = requestAnimationFrame(updateProgress);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    handleScroll(); // Initial call

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [scrollProgress]);

  // Extended content animations
  useEffect(() => {
    const observerOptions = {
      threshold: 0.15,
      rootMargin: "0px 0px -10% 0px",
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("is-visible");

          // For story paragraphs, add animation delay
          if (entry.target.classList.contains("hero__story")) {
            const paragraphs = entry.target.querySelectorAll("p");
            paragraphs.forEach((p, index) => {
              setTimeout(() => {
                p.classList.add("is-visible");
              }, index * 200);
            });
          }
        }
      });
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );

    [storyRef, founderRef, stepsRef, newsletterRef].forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <header
        ref={headerRef}
        className={`header ${isHeaderSticky ? "sticky" : ""}`}
      >
        <div className="header__container">
          <div className="header__logo">
            <img src={LogoSvg} alt="Logo" />
          </div>
          {/* <nav className="header__nav">
            <a href="#features">Features</a>
            <a href="#how-it-works">How it works</a>
            <a href="#about">About</a>
          </nav> */}
          <div className="header__cta">
            <a href="/signup" className="button button--secondary">
              Sign up for early access
            </a>
          </div>
        </div>
      </header>

      <section
        ref={heroRef}
        className={`hero ${isInView ? "in-view" : ""} ${
          isExpanded ? "hero--expanded" : ""
        }`}
        data-state={currentState}
      >
        <div className="hero__background">
          <div className="hero__gradient"></div>
          <div className="hero__expanding-bg"></div>
        </div>

        <div className="hero__container">
          <div className="hero__content" ref={contentRef}>
            <div className="hero__text">
              <h1 className="hero__title">
                Finally, a way to get back
                <br />
                <span className="text-gradient">what's yours.</span>
              </h1>
              <p className="hero__description">
                Class-action settlements—made simple.
              </p>
              <div className="hero__cta-group">
                <a href="/signup" className="button button--primary">
                  Sign Up for Early Access
                </a>
              </div>
            </div>

            <div className="hero__visuals">
              <div className="hero__phone">
                <div className="hero__phone-frame">
                  <div className="hero__phone-screen">
                    <div className={`hero__phone-content ${currentState}`}>
                      {currentState === "processing" && (
                        <div className="hero__processing">
                          <div className="hero__processing-spinner"></div>
                          <div className="hero__processing-text">
                            Processing your claim...
                          </div>
                        </div>
                      )}
                      {currentState === "completed" && (
                        <div className="hero__completed">
                          <div className="hero__completed-icon">✓</div>
                          <div className="hero__completed-text">
                            Claim Submitted!
                          </div>
                          <div className="hero__completed-amount">$150.00</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="hero__floating-elements">
                <div
                  className={`hero__notification hero__notification--settlement-1 ${currentState}`}
                >
                  <div className="hero__notification-icon">
                    <DeviceIcon />
                  </div>
                  <div className="hero__notification-content">
                    <div className="hero__notification-title">
                      Device Defects
                    </div>
                    <div className="hero__notification-subtitle">
                      Up to $95 compensation
                    </div>
                  </div>
                </div>

                <div
                  className={`hero__notification hero__notification--settlement-3 ${currentState}`}
                >
                  <div className="hero__notification-icon">
                    <HiddenFeesIcon />
                  </div>
                  <div className="hero__notification-content">
                    <div className="hero__notification-title">Hidden Fees</div>
                    <div className="hero__notification-subtitle">
                      $15 - $125 per charge
                    </div>
                  </div>
                </div>

                <div
                  className={`hero__notification hero__notification--settlement-2 ${currentState}`}
                >
                  <div className="hero__notification-icon">
                    <DataBreachIcon />
                  </div>
                  <div className="hero__notification-content">
                    <div className="hero__notification-title">Data Breach</div>
                    <div className="hero__notification-subtitle">
                      $100 - $358 compensation
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div ref={extendedContentRef} className="hero__extended-content">
            <div className="hero__story" ref={storyRef}>
              <p>
                We all know the feeling: hidden fees, surprise charges, and
                unfair terms are in every corner. Gym memberships that are
                impossible to cancel. Airlines squeezing extra dollars with
                baggage fees and seat selection. Printing fees for tickets you
                bought online. And then there are the class-action settlements
                and claims we're entitled to but never hear about—because the
                system makes it nearly impossible to access.
              </p>
              <p>
                It's frustrating, isn't it? We feel cheated and powerless. And
                it's costing us—not just money, but peace of mind.
              </p>
              <p>
                At GetBack, we believe it's time for change. It shouldn't take
                hours of research, complicated forms, or endless waiting to get
                your share of settled class actions. And you shouldn't feel
                alone in the fight to challenge unfair practices. That's why
                we've created a smarter, simpler, and fairer way to help you
                claim what's owed to you—whether it's $20 or $10,000.
              </p>
            </div>

            <div className="hero__founder" ref={founderRef}>
              <h3>Why It's Personal to Us</h3>
              <p>
                One of our founders experienced this firsthand. After
                discovering he was eligible for a class-action settlement, he
                faced years of legal hurdles, paperwork, and delays. Serving as
                the lead plaintiff, he fought for others in the same position.
              </p>
              <p>
                This experience highlighted how broken the system is. That's why
                GetBack exists—to make it easier for everyone to reclaim what's
                rightfully theirs.
              </p>
            </div>

            <div className="hero__steps" ref={stepsRef}>
              <h3>How GetBack Works</h3>
              <div className="hero__steps-grid">
                <div className="hero__step">
                  <div className="hero__step-number">
                    <svg viewBox="0 0 24 24" className="hero__icon">
                      <path
                        d="M21 15a2 2 0 01-2 2H7l-4 4V5a2 2 0 012-2h14a2 2 0 012 2z"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <h4>Discover eligible cases</h4>
                  <p>
                    Easily find class-action settlements you're entitled to.
                  </p>
                </div>
                <div className="hero__step">
                  <div className="hero__step-number">
                    <svg viewBox="0 0 24 24" className="hero__icon">
                      <path
                        d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <h4>Always free</h4>
                  <p>No hidden costs, no strings attached.</p>
                </div>
                <div className="hero__step">
                  <div className="hero__step-number">
                    <svg viewBox="0 0 24 24" className="hero__icon">
                      <path
                        d="M22 11.08V12a10 10 0 11-5.93-9.14"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22 4L12 14.01l-3-3"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <h4>Fast and secure</h4>
                  <p>
                    Get notified about claims and track your earnings in real
                    time.
                  </p>
                </div>
              </div>
            </div>

            <div className="hero__newsletter" ref={newsletterRef}>
              <h3>Join Our Newsletter</h3>
              <p>
                Right now, we're gearing up to launch, and we want you to be
                part of it. Sign up for early access, and be the first to know
                when GetBack goes live.
              </p>
              <a href="/signup" className="button button--primary">
                Sign Up for Early Access
              </a>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="footer__container">
          <div className="footer__copyright">
            © 2025 GetBack. All rights reserved.
          </div>
          <div className="footer__social">
            <a
              href="https://instagram.com/joingetback"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__social-link"
            >
              <svg viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </a>
            <a
              href="https://twitter.com/joingetback"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__social-link"
            >
              <svg viewBox="0 0 24 24" fill="currentColor">
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
              </svg>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HeroSection;
