import { useState } from "react";
import Footer from "../../components/shared/footer";
import Navbar from "../../components/shared/navbar";
import axios from "axios";
import * as Config from "../../lib/config";
import "./index.scss";

const SignupPage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      await axios.post(`${Config.getWebsiteServiceBaseUrl()}/subscribe`, {
        firstName,
        lastName,
        email,
      });
    } catch (_) {
    } finally {
      setIsSubmitted(true);
      setFirstName("");
      setLastName("");
      setEmail("");
      setIsLoading(false);
    }
  };

  return (
    <div className="signup-page">
      <Navbar />
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      <div className="hero">
        <div className="hero__content">
          <h1 className="hero__content__heading">
            Join us on our
            <br />
            <span className="text-span-accent">mission</span>
          </h1>
          <p className="hero__content__paragraph">
            Be among the first to know when we launch. Sign up for early access
            and exclusive updates on our progress. No spam—just the good stuff.
          </p>
        </div>
      </div>
      <div className="form-section">
        <div className="form-section__form-wrapper">
          {isSubmitted ? (
            <div
              className="form-section__success-message-wrapper"
              role="region"
              aria-label="Email Form success"
            >
              <div className="form-section__success-message-text">
                🎉 Thank you for joining us!
              </div>
              <p>
                We'll keep you updated on our progress and let you know as soon
                as we launch.
              </p>
            </div>
          ) : (
            <form className="form-section__form" onSubmit={handleSubmit}>
              {error && (
                <div className="form-section__error-message" role="alert">
                  {error}
                </div>
              )}
              <div className="form-section__field-group">
                <div className="form-section__field">
                  <label
                    htmlFor="first-name"
                    className="form-section__field-label"
                  >
                    First name <span>(required)</span>
                  </label>
                  <input
                    maxLength={256}
                    name="First-Name"
                    data-name="First Name"
                    placeholder="Enter your first name"
                    type="text"
                    id="first-name"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="form-section__field">
                  <label
                    htmlFor="last-name"
                    className="form-section__field-label"
                  >
                    Last Name
                  </label>
                  <input
                    maxLength={256}
                    name="Last-Name"
                    data-name="Last Name"
                    placeholder="Enter your last name"
                    type="text"
                    id="last-name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-section__field">
                <label htmlFor="email" className="form-section__field-label">
                  Email <span>(required)</span>
                </label>
                <input
                  maxLength={256}
                  name="email"
                  data-name="Email"
                  placeholder="Enter your email address"
                  type="email"
                  id="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="submit-button"
                disabled={!firstName || !email || isLoading}
              >
                {isLoading ? "Submitting..." : "Sign up for early access"}
              </button>
            </form>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignupPage;
