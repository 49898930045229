import HeroSection from "../components/landing-page/hero";
// import FeaturesGrid from "../components/landing-page/features-grid";
// import AppShowcase from "../components/landing-page/app-showcase";
// import BenefitsSection from "../components/landing-page/benefits";
// import GlobalStats from "../components/landing-page/global-stats";

const LandingPage = () => {
  return (
    <main>
      <HeroSection />
      {/* <FeaturesGrid />
      <AppShowcase />
      <BenefitsSection />
      <GlobalStats /> */}
    </main>
  );
};

export default LandingPage;
