interface IconProps {
  className?: string;
}

export const DeviceIcon = ({ className }: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
    width="24"
    height="24"
  >
    <rect x="3" y="4" width="18" height="16" rx="2" />
    <path d="M16 2v4" />
    <path d="M8 2v4" />
    <path d="M7 10h10" />
    <path d="M7 14h10" />
  </svg>
);

export const DataBreachIcon = ({ className }: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
    width="24"
    height="24"
  >
    <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
  </svg>
);

export const HiddenFeesIcon = ({ className }: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
    width="24"
    height="24"
  >
    <circle cx="12" cy="12" r="10" />
    <path d="M16 8l-8 8" />
    <path d="M8 8l8 8" />
  </svg>
);
